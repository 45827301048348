import {createSlice} from "@reduxjs/toolkit";
import type {PayloadAction} from "@reduxjs/toolkit";
import type {RootState} from "../store";
import {LenderState} from "interfaces/redux";

const initialState: LenderState = {
  searchTerm: "",
  amount: "500",
  month: "1",
  websiteUrl: "",
};

export const lenderSlice = createSlice({
  name: "lender",
  initialState,
  reducers: {
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setAmount: (state, action: PayloadAction<string>) => {
      state.amount = action.payload;
    },
    setTenor: (state, action: PayloadAction<string>) => {
      state.month = action.payload;
    },
    setWebsiteUrl: (state, action: PayloadAction<string>) => {
      state.websiteUrl = action.payload;
    },
  },
});

export const {setSearchTerm, setAmount, setTenor, setWebsiteUrl} =
  lenderSlice.actions;

export const selectSearchTerm = (state: RootState) => state.lender.searchTerm;

export default lenderSlice.reducer;
