var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"w_r2EODKH50YrLxx84tNa"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"

const isBrowser = typeof window !== "undefined"

const SENTRY_DSN =
  "https://6a2e63882f0a5a86bfa182fa9fe041ef@sentry.obscudo.com/26"

Sentry.init({
  dsn: SENTRY_DSN || "null",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.5,

  replaysSessionSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  enabled: isBrowser && window.location.hostname !== "localhost",
})
