import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import {getStrapiCountries} from "@utils/data";
import axios from "axios";

export interface Lender {
  short_logo_url: string | undefined;
  logo_url?: string | null;
  minimum_tenor: number;
  maximum_tenor: number;
  tenor_type: string;
  phone_number: string;
  website: string;
  email: string;
  id: number;
  org_name: string;
  minimum_amount: number;
  maximum_amount: number;
  currency: string;
  lender_type: string;
  web_app_url?: string;
  facebook_url?: string;
  twitter_url?: string;
  whatsapp_url?: string;
  lender_category: string;
  is_lender_spotlight: string;
  countries: string;
  country: string;
  requirements: string;
  overall_rating: string;
  app_store_url: string;
  playstore_url: string;
  about: any;
  // Add other properties as needed
}

interface LendersState {
  lenders: {id: number; attributes: Lender}[];
  countries: {option: string; value: string}[];
  lenderTypes: {option: string; value: string}[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  total: number;
  currentPage: number;
  totalPages: number;
  perPage: number;
  allLendersPosition: null;
}

const initialState: LendersState = {
  lenders: [],
  countries: [],
  lenderTypes: [],
  status: "idle",
  error: null,
  total: 0,
  perPage: 12,
  currentPage: 1,
  totalPages: 0,
  allLendersPosition: null,
};

export const fetchLenders = createAsyncThunk(
  "lenders/fetchLenders",
  async (_, thunkAPI) => {
    // Check if lenders data is available in sessionStorage
    const storedLenders = sessionStorage.getItem("lenders");
    if (storedLenders) {
      const parsedData = JSON.parse(storedLenders);

      const uniqueCountries = new Set(
        parsedData
          .map(
            (item: {id: number; attributes: Lender}) => item.attributes.country
          )
          .filter(Boolean)
      );

      const defaultCountries = Array.from(uniqueCountries).map((country) => ({
        option: country,
        value: country,
      }));

      const countries = (await getStrapiCountries()) || defaultCountries;

      console.log(uniqueCountries, countries, "countries");

      const uniqueLenderTypes = new Set(
        parsedData
          .map(
            (item: {id: number; attributes: Lender}) =>
              item.attributes.lender_type
          )
          .filter(Boolean)
      );
      const lenderTypes = Array.from(uniqueLenderTypes).map((lender_type) => ({
        option: lender_type,
        value: lender_type,
      }));

      sessionStorage.setItem("lender_countries", JSON.stringify(countries));
      sessionStorage.setItem("lender_type", JSON.stringify(lenderTypes));
      return parsedData;
    } else {
      try {
        const response = await axios.get(
          `${process.env.NEXT_PUBLIC_STRAPI_BASE_URL}/lenders?populate=*&pagination[page]=1&pagination[pageSize]=100`,
          {
            headers: {
              Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_KEY}`,
            },
          }
        );

        let savedData = [...response.data.data];
        const totalPages = response.data.meta.pagination.pageCount;

        // fetching data from other pages
        for (let i = 2; i <= totalPages; i++) {
          const response = await axios.get(
            `${process.env.NEXT_PUBLIC_STRAPI_BASE_URL}/lenders?populate=*&pagination[page]=${i}&pagination[pageSize]=100`,
            {
              headers: {
                Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_KEY}`,
              },
            }
          );

          savedData = [...savedData, ...response.data.data];
        }

        const uniqueCountries = new Set(
          savedData
            .map(
              (item: {id: number; attributes: Lender}) =>
                item.attributes.country
            )
            .filter(Boolean)
        );

        const defaultCountries = Array.from(uniqueCountries).map((country) => ({
          option: country,
          value: country,
        }));

        const countries = (await getStrapiCountries()) || defaultCountries;
        // Extract lender type
        const uniqueLenderTypes = new Set(
          savedData.map((item) => item.attributes.lender_type).filter(Boolean)
        );
        const lenderTypes = Array.from(uniqueLenderTypes).map(
          (lender_type) => ({
            option: lender_type,
            value: lender_type,
          })
        );

        sessionStorage.setItem("lender_countries", JSON.stringify(countries));
        sessionStorage.setItem("lender_type", JSON.stringify(lenderTypes));
        sessionStorage.setItem("lenders", JSON.stringify(savedData));

        return savedData;
      } catch (error: any) {
        return thunkAPI.rejectWithValue({error: error.message});
      }
    }
  }
);

const allLendersSlice = createSlice({
  name: "lenders",
  initialState,
  reducers: {
    loadMore: (state) => {
      state.perPage += 12;
    },
    calculateLenders: (state) => {
      state.status = "loading";
    },
    releaseLenders: (state) => {
      state.status = "succeeded";
    },
    filterLenders: (state, action: PayloadAction<any>) => {
      state.lenders = action.payload;
    },
    searchLenders: (state, action) => {
      const storedData = sessionStorage.getItem("lenders");

      if (storedData) {
        const providerData = JSON.parse(storedData);

        if (action.payload.length === 0) {
          state.lenders = providerData;
        } else {
          const filterDataByName = providerData.filter((item: any) =>
            item.attributes.org_name
              .toLowerCase()
              .includes(action.payload.toLowerCase())
          );

          state.lenders = filterDataByName;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLenders.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLenders.fulfilled, (state, action: PayloadAction<any>) => {
        const countries: {option: string; value: string}[] = JSON.parse(
          sessionStorage.getItem("lender_countries") as string
        );
        const lenderTypes: {option: string; value: string}[] = JSON.parse(
          sessionStorage.getItem("lender_type") as string
        );

        state.status = "succeeded";
        state.lenders = action.payload;

        state.countries = countries;
        state.lenderTypes = lenderTypes;

        state.total = action.payload.length;
      })
      .addCase(fetchLenders.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch offers";
      });
  },
});

export const {
  calculateLenders,
  releaseLenders,
  loadMore,
  // sortList,
  filterLenders,
  searchLenders,
} = allLendersSlice.actions;

export default allLendersSlice.reducer;
