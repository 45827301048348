import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import axios from "axios";

export interface Provider {
  id: string;
  provider: string;
  country: string;
  services: string;
  phone_number: string;
  email: string;
  website: string;
  whatsapp_url: string;
  facebook_url: string;
  twitter_url: string;
  full_logo: any;
  short_logo_url: string;
  service_type: string;
  countries: string[];
  category_type: string;
  address: string;
  instagram_url: string;
  about: any;
}

interface AllProvidersState {
  providers: {id: number; attributes: Provider}[];
  countries: {option: string; value: string}[];
  serviceTypes: {option: string; value: string}[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  perPage: number;
  total: number;
}

const initialState: AllProvidersState = {
  providers: [],
  countries: [],
  serviceTypes: [],
  status: "idle",
  error: null,
  perPage: 12,
  total: 0,
};

// Define the async thunk
export const fetchProviders = createAsyncThunk<
  {providers: {id: number; attributes: Provider}[]; countries: string[]}, // Correct return type
  void,
  {rejectValue: {error: string}}
>("allProviders/fetchProviders", async (_, thunkAPI) => {
  const storedData = sessionStorage.getItem("providers");

  if (storedData) {
    const parsedData = JSON.parse(storedData);

    const uniqueCountries = new Set(
      parsedData
        .map(
          (item: {id: number; attributes: Provider}) => item.attributes.country
        )
        .filter(Boolean)
    );
    const countries = Array.from(uniqueCountries).map((country) => ({
      option: country,
      value: country,
    }));

    const uniqueServiceTypes = new Set(
      parsedData
        .map(
          (item: {id: number; attributes: Provider}) =>
            item.attributes.service_type
        )
        .filter(Boolean)
    );

    const serviceTypes = Array.from(uniqueServiceTypes).map((service_type) => ({
      option: service_type,
      value: service_type,
    }));

    sessionStorage.setItem("provider_countries", JSON.stringify(countries));
    sessionStorage.setItem("service_types", JSON.stringify(serviceTypes));

    return parsedData;
  } else {
    try {
      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_STRAPI_BASE_URL}/project-x-providers?populate=*&pagination[page]=1&pagination[pageSize]=100`,
        {
          headers: {
            Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_KEY}`,
          },
        }
      );

      let savedData = [...response.data.data];
      const totalPages = response.data.meta.pagination.pageCount;

      // fetching data from other pages
      for (let i = 2; i <= totalPages; i++) {
        const response = await axios.get(
          `${process.env.NEXT_PUBLIC_STRAPI_BASE_URL}/project-x-providers?populate=*&pagination[page]=${i}&pagination[pageSize]=100`,
          {
            headers: {
              Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_KEY}`,
            },
          }
        );
        savedData = [...savedData, ...response.data.data];
      }

      // Extract countries and ensure they are strings
      const uniqueCountries = new Set(
        savedData.map((item) => item.attributes.country).filter(Boolean)
      );
      const countries = Array.from(uniqueCountries).map((country) => ({
        option: country,
        value: country,
      }));

      const uniqueServiceTypes = new Set(
        savedData.map((item) => item.attributes.service_type).filter(Boolean)
      );
      const serviceTypes = Array.from(uniqueServiceTypes).map(
        (service_type) => ({
          option: service_type,
          value: service_type,
        })
      );

      // console.log("total countries: ", countries);
      sessionStorage.setItem("provider_countries", JSON.stringify(countries));
      sessionStorage.setItem("service_types", JSON.stringify(serviceTypes));
      sessionStorage.setItem("providers", JSON.stringify(savedData));

      return savedData;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
});

// Create the slice
const allProvidersSlice = createSlice({
  name: "allProviders",
  initialState,
  reducers: {
    loadMore: (state) => {
      state.perPage += 12;
    },
    // NOTE - Custon loader to filter the list with calculator data
    calculateProviders: (state) => {
      state.status = "loading";
    },
    releaseProviders: (state) => {
      state.status = "succeeded";
    },
    searchProviders: (state, action) => {
      const storedData = sessionStorage.getItem("providers");

      if (storedData) {
        const providerData = JSON.parse(storedData);

        if (action.payload.length === 0) {
          state.providers = providerData;
        } else {
          const filterDataByName = providerData.filter(
            (item: any) =>
              item.attributes.provider
                .toLowerCase()
                .includes(action.payload.toLowerCase()) ||
              item.attributes.service_type
                .toLowerCase()
                .includes(action.payload.toLowerCase())
          );

          state.providers = filterDataByName;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProviders.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchProviders.fulfilled,
        // TODO - Update interface for response
        (state, action: PayloadAction<any>) => {
          const countries: {option: string; value: string}[] = JSON.parse(
            sessionStorage.getItem("provider_countries") as string
          );
          const serviceTypes: {option: string; value: string}[] = JSON.parse(
            sessionStorage.getItem("service_types") as string
          );

          state.status = "succeeded";
          state.providers = action.payload;
          state.countries = countries;
          state.serviceTypes = serviceTypes;
          state.total = action.payload.length;
        }
      )
      .addCase(
        fetchProviders.rejected,
        (state, action: PayloadAction<{error: string} | undefined>) => {
          state.status = "failed";
          state.error = action.payload?.error ?? "Failed to fetch providers";
        }
      );
  },
});

export const {calculateProviders, releaseProviders, loadMore, searchProviders} =
  allProvidersSlice.actions;

export default allProvidersSlice.reducer;
