import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
	// baseUrl: "https://53fea460-17c9-44dc-978d-605d1c935a2c.mock.pstmn.io/v1",
	baseUrl:
		"https://script.google.com/macros/s/AKfycbyGIgJLwEvkW6mLdbbnye1yOVQh-c_AMFT6xUbFoYZ7uXkbwkm6nGfonTzIqfXf-Mg",
	prepareHeaders: (headers) => {
		return headers;
	},
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 1 });

export const api = createApi({
	reducerPath: "splitApi",
	baseQuery: baseQueryWithRetry,
	tagTypes: [],
	endpoints: () => ({}),
});
