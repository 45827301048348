import {createContext} from "react";

export interface DownloadModalProps {
  showDownloadModal: boolean
  setShowDownloadModal: (showDownloadModal: boolean) => void
}

export const DownloadModalContext = createContext<DownloadModalProps>({
  showDownloadModal: false,
  setShowDownloadModal: (showDownloadModal: boolean) => {
    return showDownloadModal;
  },
});
